<template>
  <div
    :class="['w-full h-full group/status', { '!cursor-not-allowed': disabled }]"
  >
    <TaskMovePopover
      ref="taskMovePopoverRef"
      :workspace-id="workspaceId"
      :tasks="[task]"
      class="w-full grow"
    >
      <div
        :class="[
          'w-full h-full px-2 py-1.5 max-h-8 ',
          { '!cursor-not-allowed': disabled },
          cellClass,
          { active: open },
        ]"
      >
        <UButton
          variant="soft"
          color="white"
          class="text-gray-900 w-full h-full font-normal truncate"
          size="2xs"
          data-test="section-btn"
          :disabled="disabled"
        >
          <span class="truncate">{{ task.section?.name }}</span>
        </UButton>
      </div>
    </TaskMovePopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'

defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  cellClass: {
    type: String,
    default: '',
  },
  workspaceId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'set-callback', callback: { open: () => void }): void
  (e: 'update:open', value: boolean): void
}>()

const open = ref(false)

onMounted(() => {
  emit('set-callback', {
    open: () => {
      open.value = true
    },
  })
})
</script>
